/********************************************************************************/

// Global styling for this template
@import 'src/styles/_variables.scss';
// Import Bootstrap
@import "bootstrap/scss/bootstrap.scss";
// Import Bootstrap variables for use within theme
@import "bootstrap/scss/functions.scss";
@import "bootstrap/scss/variables.scss";
html,
body {
    height: 100%;
    // font-size: 85%;
}

// Set spacing on containers for page symmetry
.container,
.container-fluid {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
}

.container-fluid:after {
    background: transparent !important;
}

.form-control {
    // font-size: 100% !important;
}

.alert {
    position: fixed;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    top: 40%;
    z-index: 9999;
    left: 30%;
    /* max-width: 980px; */
    width: 50%;
    /* margin: auto; */
    font-size: 14px;
    text-align: center;
}

.alert-bg {
    background: rgb(0 0 0 / 13%);
    z-index: 999;
    position: fixed;
    width: 100%;
    height: 100%;
}

.alert-danger {
    background-color: #ffaeb5;
}