
// Import Bootstrap
@import "bootstrap/scss/bootstrap.scss";

// Import Bootstrap variables for use within theme
@import "bootstrap/scss/functions.scss";
@import "bootstrap/scss/variables.scss";

// Z index variables
$zindex-sidenav: 1080 !default;
$zindex-topnav: 1090 !default;

// Color variables for the sidenav

// -- Sidenav Dark
$sidenav-dark-bg: $gray-900;
$sidenav-dark-color: fade-out($white, 0.5);
$sidenav-dark-heading-color: fade-out($white, 0.75);
$sidenav-dark-link-color: fade-out($white, 0.5);
$sidenav-dark-link-active-color: $white;
$sidenav-dark-icon-color: fade-out($white, 0.75);
$sidenav-dark-footer-bg: $gray-800;

// -- Sidenav Light
$sidenav-light-bg: $gray-100;
$sidenav-light-color: $gray-900;
$sidenav-light-heading-color: $gray-500;
$sidenav-light-link-color: $sidenav-light-color;
$sidenav-light-link-active-color: $primary;
$sidenav-light-icon-color: $gray-500;
$sidenav-light-footer-bg: $gray-200;

// Color variables for the topnav
$topnav-dark-toggler-color: fade-out($white, 0.5);
$topnav-light-toggler-color: $gray-900;

// Adjust column spacing for symmetry
$spacer: 1rem;
$grid-gutter-width: $spacer * 1.5;

// Spacing variables for navigation
$topnav-base-height: 56px;
$sidenav-base-width: 225px;

// Fonts
$font-default: 'Open Sans', sans-serif;
$font-primary: 'Lato', sans-serif;
$font-secondary: 'Poppins', sans-serif;
$font-h1:"Source Serif Pro", "Times New Roman", Times, serif;
$font-h2:"Lora", "Times New Roman", Times, serif;

// Colors
$default: #444444;
$primary: #1977cc;
$secondary: #2c4964;

// General Colors
$red: #dc3545;
$green: #28a745;
$blue: #007bff;
$yellow: #ffc107;
$orange: #fd7e14;
$brown: #7b4f2c;
$purple: #6f42c1;
$pink: #e83e8c;
$white: #fff;
$gray: #6c757d;
$black: #343a40;
$teal: #20c997;
$cyan: #17a2b8;

$background:#f9f8f6;


//appointment-form
$form-btn-color:blue;
$form-input-border:#eae7df;
$form-input-focus:#719ECE

